import React from 'react'
import '../css/rainbow.css'
import rainout from '../Images/rainout.png'
import rainin from '../Images/rainin.png'
import raingoogle from '../Images/raingoogle.png'
import learnmore from '../Images/learnmore.png'

function Rainbow() {
  return (
    <div className="rainbowMain">
        <div className="hSHeading">
        <h1>Ascend with our Creativity</h1>
      </div>

      <div className="rainbowCon">
        <img className='rainoutimg' src={rainout} alt="" />
        <div className="rainContent">
            <img className='raininimg' src={rainin} alt="" />
            <div className="raininCon">
                <div className="logohead">
                    <img className='raingimg' src={raingoogle} alt="" />
                    <p>CREATIVE <br /> TOKENS</p>
                </div>
                <div className="logopara"><p>Go with our creativity in design or ideation, Share a brief or a problem and its done!</p></div>

                <div className="logobtns">
                    <button className='learnmore' ><img src={learnmore} alt="Learn More" />Learn More</button>
                    <button className='signup' ><img src={learnmore} alt="Sign up" />Sign up</button>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Rainbow