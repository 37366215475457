import React, { useState, useEffect } from "react";
import "../css/explorecs.css";
import "../css/homeslider.css";
import leftaa from "../Images/leftaa.png";
import motionBg from '../Images/slider/MotionBg.png'
import motionIn from '../Images/slider/MotionIn.png'
import socialBg from '../Images/slider/SocialBg.png'
import socialIn from '../Images/slider/SocialIn.png'
import starterBg from '../Images/slider/StarterBg.png'
import starterIn from '../Images/slider/StarterIn.png'
import trendBg from '../Images/slider/TrendBg.png'
import trendIn from '../Images/slider/TrendIn.png'
import supportBg from '../Images/slider/SupportBg.png'
import supportIn from '../Images/slider/SupportIn.png'
import { Link } from "react-router-dom";

function ExploreCS() {
  const [totalSlides, setTotalSlides] = useState(6);
  const [slidePosition, setSlidePosition] = useState(0); // Tracks the current position
  const [clickCount, setClickCount] = useState(0); // Tracks the number of clicks
  const [visibleSlides, setVisibleSlides] = useState(1); // Number of visible slides based on screen width

  // Handle screen width and adjust visible slides
  useEffect(() => {
    const updateVisibleSlides = () => {
      const width = window.innerWidth;
      if (width <= 767) {
        setVisibleSlides(1); // 1 visible 
        setTotalSlides(6);
      } else if (width <= 1023) {
        setVisibleSlides(2); // 2 visible slides
        setTotalSlides(6);
      } else if (width <= 1439) {
        setVisibleSlides(3); // 3 visible slides
        setTotalSlides(5);
      } else {
        setVisibleSlides(4); // 4 visible slides
        setTotalSlides(4);
      }
    };

    updateVisibleSlides(); // Call on component mount
    window.addEventListener("resize", updateVisibleSlides); // Listen for window resize

    return () => {
      window.removeEventListener("resize", updateVisibleSlides); // Cleanup listener on unmount
    };
  }, []);

  // Handle the click to slide
  const handleSlide = () => {
    // const totalSlides = 6; 

    setClickCount((prevCount) => {
      const newCount = prevCount + 1;

      // Move left by 290px only
      if (newCount < totalSlides) {
        setSlidePosition((prevPosition) => prevPosition + 290); // Always move by 290px
      } else {
        // On reaching the end, reset both position and click count
        setSlidePosition(0);
        return 0; // Reset the click count
      }

      return newCount;
    });
  };

  return (
    <div className="exploreCSMain">
      <div className="hSHeading">
        <h1>Explore Custom Solutions</h1>
      </div>
      <div className="sliderMainCS">
        <div className="exploreBtns">
          <div onClick={handleSlide} className="ogimg"><img src={leftaa} alt="Slide Left"  /></div>
        </div>
        <div className="sliderWindow2">
          <div
            className="exploreSlide"
            style={{ marginLeft: `-${slidePosition}px` }}>
            <Link to={'/solutions/green'} className="RfSlide starterSL">
                        <h2>DIGITAL <br /> BRANDING</h2>
                        <h1>STARTER</h1>
                        <div className="rfcImg">
                            <img src={starterBg} alt="" />
                            <img src={starterIn} alt="" />
                        </div>
                        <p>Just get started! <br /> Make your brand work with this Affordable Solution Great For startups.</p>
                    </Link>
                    <Link to={'/solutions/blue'} className="RfSlide social">
                        <h2>DIGITAL <br /> BRANDING</h2>
                        <h1>SOCIAL</h1>
                        <div className="rfcImg">
                            <img src={socialBg} alt="" />
                            <img src={socialIn} alt="" />
                        </div>
                        <p>Time to show off! <br /> Keep your brand pinned on Internet, increase consistency and create the growth you deserve across the internet.</p>
                    </Link>
                    <Link to={'/solutions/yellow'} className="RfSlide motion">
                        <h2>DIGITAL <br /> BRANDING</h2>
                        <h1>MOTION</h1>
                        <div className="rfcImg">
                            <img src={motionBg} alt="" />
                            <img src={motionIn} alt="" />
                        </div>
                        <p>Tired of boring transitions? Keep your brand videos, Commercials, Animations, or even a logo in motion with our high-end production team</p>
                    </Link>
                    <Link to={'/solutions/red'} className="RfSlide trend">
                        <h2>DIGITAL <br /> BRANDING</h2>
                        <h1>TREND +</h1>
                        <div className="rfcImg">
                            <img src={trendBg} alt="" />
                            <img src={trendIn} alt="" />
                        </div>
                        <p>Let’s make it extensive. Stand out first and create Trends or first to follow them, With our research brain let’s make it Exclusive.</p>
                    </Link>
                    <Link to={'/solutions/purple'} className="RfSlide support">
                        <h2>DIGITAL <br /> BRANDING</h2>
                        <h1>SUPPORT</h1>
                        <div className="rfcImg">
                            <img src={supportBg} alt="" />
                            <img src={supportIn} alt="" />
                        </div>
                        <p>Stuck on a project? <br />
                        Let our experts help you Create on-demand and Faster</p>
                    </Link>
                    <Link to={'/solutions/green'} className="RfSlide explore">
                        <h1>EXPLORE <br /> MORE WAYS</h1>
                        <img src={leftaa} alt="" />
                    </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExploreCS;
