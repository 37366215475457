import React, { useEffect, useState } from 'react';
import rf from '../Images/redfibre logo.png'
import starter from '../Images/Solutions/RFsolution starterView 1.png'
import social from '../Images/Solutions/RFsolution SOCIALView 1.png'
import motion from '../Images/Solutions/RFsolution MOTIONView 1.png'
import trend from '../Images/Solutions/RFsolution TRENDView 1.png'
import pro from '../Images/Solutions/RFsolution proView 1.png'
import floor1 from '../Images/Solutions/floor1.png'
import floor2 from '../Images/Solutions/floor2.png'
import floor3 from '../Images/Solutions/floor3.png'
import floor4 from '../Images/Solutions/floor4.png'
import floor5 from '../Images/Solutions/floor5.png'
import greenIcon from '../Images/Solutions/green.png'
import blueIcon from '../Images/Solutions/blue.png'
import redIcon from '../Images/Solutions/red.png'
import yellowIcon from '../Images/Solutions/yellow.png'
import purpleIcon from '../Images/Solutions/purple.png'
import { Link } from 'react-router-dom';

function Subscriptions({scolor}) {

    const [glitch, setGlitch] = useState(false);

  useEffect(() => {
    const glitchInterval = setInterval(() => {
      setGlitch(true);
      setTimeout(() => {
        setGlitch(false);
      }, 200);
    }, 2500);
    return () => clearInterval(glitchInterval);
  }, []);

  const [stHead, setStHead] = useState('STARTER')
  const [selectedColor, setSelectedColor] = useState( scolor || 'green');
  const [textColor, setTextColor] = useState('#1AEB5F')
  const [acShadow, setAcShadow] = useState('#0AFF003B')
  const [textStyle, setTextStyle] = useState({
    position: 'relative',
    left: '0'
  })
  const [imgStyle, setImgStyle] = useState({
    transform: 'scale(1)'
  })
  const [content, setContent] = useState([
    "- GRAPHIC DESIGNING",
    "- STATIC WEBSITE DEVELOPMENT",
    "- WEBSITE MAINTENANCE & UPDATION",
    "- SOCIAL MEDIA STRATEGY & TEMPLATE",
    "- BASIC VIDEO EDITING"
  ]);
  const [normalImg, setNormalImg] = useState(starter)
  const [floor, setFloor] = useState(floor1)
  const [colorCode, setColorCode] = useState(scolor);



  const handleColorClick = (color) => {
    setTextStyle({
        position: 'relative',
        left: '-400px',
        opacity: '0',
        transform: 'scale(0.7)'
    })
    setImgStyle({
        transform: 'scale(0.7)'
    })
    setTimeout(() => {
        setTextStyle({
            position: 'relative',
            left: '0px',
            opacity: '1',
            transform: 'scale(1)'
        })
        setImgStyle({
            transform: 'scale(1)'
        })
    }, 500);
    
    setTimeout(() => {
        setSelectedColor(color);
        if(color === 'green'){
            setTextColor('#1AEB5F')
            setAcShadow('#0AFF003B')
            setStHead('STARTER')
            setContent([
                "- GRAPHIC DESIGNING",
                "- STATIC WEBSITE DEVELOPMENT",
                "- WEBSITE MAINTENANCE & UPDATION",
                "- SOCIAL MEDIA STRATEGY & TEMPLATE",
                "- BASIC VIDEO EDITING",
            ])
            setNormalImg(starter)
            setFloor(floor1)
        }else if(color === 'blue'){
            setTextColor('#00B1FF')
            setAcShadow('#00FFF53B')
            setStHead('SOCIAL')
            setContent([
                "- SOCIAL MEDIA STRATEGY",
                "- CONTENT CREATION",
                "- ONLINE REPUTATION MANAGEMENT",
                "- BMS & PORTFOLIO WEBSITE",
                "- ADVANCED SEO",
              ]);
            setNormalImg(social)
            setFloor(floor2)
        }else if(color === 'yellow'){
            setTextColor('#FFD100')
            setAcShadow('#FFEB003B')
            setStHead('MOTION')
            setContent([
                "- VIDEO EDITING & MOTION GRAPHICS",
                "- CONTENT CREATION",
                "- AUDIO EDITING",
                "- CMS & PORTFOLIO WEBSITE",
                "- FASTER AND ON-DEMAND",
            ])
            setNormalImg(motion)
            setFloor(floor3)
        }else if(color === 'red'){
            setTextColor('#FF3A2B')
            setAcShadow('#FF00003B')
            setStHead('TREND +')
            setContent([
                "- CURATED STRATEGY EVERY WEEK",
                "- FREE CONSULTATION ON DEMAND",
                "- WEEKLY ANALYSIS OF BRAND",
                "",
                "",
            ])
            setNormalImg(trend)
            setFloor(floor4)
        }else if(color === 'purple'){
          setTextColor('#F41392')
          setAcShadow('#FF00F53B')
          setStHead('PRO')
          setContent([
              "- GRAPHIC DESIGNING",
              "- STATIC AND DYNAMIC WEBSITE DEVELOPMENT",
              "- WEBSITE MAINTENANCE & UPDATION",
              "-INCLUDES ALL FEATURES FROM SOCIAL, MOTION, AND TREND +",
              "- FASTER DELIVERY",
          ])
          setNormalImg(pro)
          setFloor(floor5)
        }
    }, 500);
  };

  useEffect(() => {
    handleColorClick(colorCode)
  }, [colorCode])
  
  

  return (
    <div>
        <div className="sb-icons">
        <div className="sbi" id='gi' onClick={() => setColorCode('green')}
        style={{ outline: selectedColor === 'green' ? '2px solid white' : 'none', background: selectedColor === 'green' ? 'black' : 'transparent' }}><img src={greenIcon} alt="greenIcon" /></div>
        <div className="sbi" id='bi' onClick={() => setColorCode('blue')}
        style={{ outline: selectedColor === 'blue' ? '2px solid white' : 'none', background: selectedColor === 'blue' ? 'black' : 'transparent' }}><img src={blueIcon} alt="blueIcon" /></div>
        <div className="sbi" id='yi' onClick={() => setColorCode('yellow')}
        style={{ outline: selectedColor === 'yellow' ? '2px solid white' : 'none', background: selectedColor === 'yellow' ? 'black' : 'transparent' }}><img src={yellowIcon} alt="yellowIcon" style={{paddingLeft: '5px'}}/></div>
        <div className="sbi" id='ri' onClick={() => setColorCode('red')}
        style={{ outline: selectedColor === 'red' ? '2px solid white' : 'none', background: selectedColor === 'red' ? 'black' : 'transparent' }}><img src={redIcon} alt="redIcon" /></div>
        <div className="sbi" id='pi' onClick={() => setColorCode('purple')}
        style={{ outline: selectedColor === 'purple' ? '2px solid white' : 'none', background: selectedColor === 'purple' ? 'black' : 'transparent' }}><img src={purpleIcon} alt="purpleIcon" /></div>
    </div>
     <div className="subDesc">
        <div className="allGlitches" style={imgStyle}>
            <div className={`glitch-container ${glitch ? 'glitch' : ''}`}>
            <img src={normalImg} alt="Glitch Image" className="glitch-image" />
            </div>
            <img src={normalImg} alt="Glitch Image" className='gi-back'/>
        </div>
        <div className="starter" style={textStyle}>
            <h1>
                <p className='st-db' style={{ color: textColor }}>DIGITAL BRANDING</p>
                <p style={{color: "white"}}>{stHead}</p>
            </h1>
            <p className='month-sb'>MONTHLY SUBSCRIPTION</p>
            <div className="starter-desc">
                {content.map((item, index) => (
                    <p key={index}>{item}</p>
                ))}
            </div>
            <Link to={'/contact'}> 
            <button className='ac-now' style={{ backgroundColor: textColor, boxShadow: `0px 20px 20px ${acShadow}` }}>CONTACT</button>
            </Link>
        </div>
        <img src={floor} alt="floor1" className='floor'/>
     </div>
    </div>
  )
}

export default Subscriptions