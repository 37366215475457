import './App.css';
import Home from './Components/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Services from './Components/Services';
import Strategy from './Components/Strategy';
import ScrollToTop from './Components/ScrollToTop';
import Contact from './Components/Contact';
import Solutions from './Components/Solutions';
import Profile from './Components/Profile';
import { useEffect, useState } from 'react';
import MobileView from './Components/MobileView';
import Dashboard from './Components/Dashboard';
import Projects from './Components/Projects';

import './css/media.css'
import Login from './Components/Login';
import Singup from './Components/Signup';

function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <Router>
      <ScrollToTop/>
        <Routes>
          {/* <Route path='/' element={isMobile ? <MobileView/> : <Home/>}/> */}
          <Route path='/' element={<Home/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/solutions' element={<Solutions/>}/>
          <Route path='/solutions/:scolor' element={<Solutions/>}/>
          <Route path='/strategy' element={<Strategy/>}/>
          <Route path='/profile' element={<Profile/>}>
            <Route path='/profile' element={<Dashboard/>}/>
            <Route path='/profile/projects' element={<Projects/>}/>
          </Route>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/signup' element={<Singup/>}/>
        </Routes>
    </Router>
  );
}

export default App;