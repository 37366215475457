import React from 'react'
import Navbar from './Navbar'
import Hmain1 from './Hmain1'
import '../css/home.css'
import Hmain2 from './Hmain2'
import Footer from './Footer'
import { useEffect } from 'react'
import HomeSlider from './HomeSlider'
import '../css/homeslider.css'
// import ExploreCS from './ExploreCS'

function Home() {

  useEffect(() => {
    const handleLoad = () =>{
      window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    } 
  
    window.addEventListener('load', handleLoad)
    return () => window.removeEventListener("load", handleLoad);
  }, [])
  
  return (
    <div className='home'>
        <Navbar page={'home'}/>
        <Hmain1/>
        {/* <Hmain2/> */}
        <HomeSlider/>
        {/* <ExploreCS/> */}
        <Footer page={'home'}/>
    </div>
  )
}

export default Home