import React, { useEffect, useRef } from 'react';
import Solmain3 from './Solmain3';
import Navbar from './Navbar';
import '../css/solutions.css';
import Solmain1 from './Solmain1';
import Solmain2 from './Solmain2';
import Footer from './Footer';
import { useParams } from 'react-router-dom';

function Solutions() {
  const targetDivRef = useRef(null);
  const {scolor}=useParams()
  // console.log(scolor)

  useEffect(() => {

    window.scrollTo({
      top: 0,
      // behavior: 'smooth',
    });
    // const scrollToDiv = () => {
      setTimeout(() => {
        window.scrollTo({
          top: targetDivRef.current.offsetTop,
          behavior: 'smooth',
        });
      }, 200);
    // };

  }, []);

  return (
    <div className='solution wwc'>
      <Navbar page="sol" />
      <Solmain1 />
      <Solmain2 />
      <div ref={targetDivRef}><Solmain3 scolor={scolor}/></div>
      <Footer page={'solutions'} />
    </div>
  );
}

export default Solutions;
