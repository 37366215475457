import React, { useEffect, useState } from 'react';
import Subscriptions from './Subscriptions';
import SupportPack from './SupportPack';
import Consultation from './Consultation';

const Solmain3 = ({scolor}) => {
  
const [currentComponent, setCurrentComponent] = useState(scolor === 'purple' ? 'ComponentTwo' : 'ComponentOne');

  const switchToComponentOne = () => {
    setCurrentComponent('ComponentOne');
  };

  const switchToComponentTwo = () => {
    setCurrentComponent('ComponentTwo');
  };

  const switchToComponentThree = () => {
    setCurrentComponent('ComponentThree');
  };

  return (
   <div className="solmain3">
    <div className="ssc">
        <div className="ssc-d" onClick={switchToComponentOne} style={{ outline: currentComponent === 'ComponentOne' ? '2px solid white' : 'none' }}>SUBSCRIPTIONS</div>
        <div className="ssc-d" onClick={switchToComponentTwo} style={{ outline: currentComponent === 'ComponentTwo' ? '2px solid white' : 'none' }}>SUPPORT-PACK</div>
        <div className="ssc-d" onClick={switchToComponentThree} style={{ outline: currentComponent === 'ComponentThree' ? '2px solid white' : 'none' }}>CONSULTATION</div>
    </div>
    {currentComponent === 'ComponentOne' && <Subscriptions scolor={scolor}/>}
      {currentComponent === 'ComponentTwo' && <SupportPack/>}
      {currentComponent === 'ComponentThree' && <Consultation/>}
    
     
   </div>
  );
};

export default Solmain3;
