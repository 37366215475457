import React, { useState, useEffect } from 'react';
import leftaa from '../Images/leftaa.png';
import rightag from '../Images/rightag.png';
import brain from '../Images/brain.png';
import bulb from '../Images/bulb.png';
import play from '../Images/play.png';
import osnew from '../Images/osnew.png';
import pbnew from '../Images/pbnew.png';
import webdesign from '../Images/webdesign.png';
import appideationh from '../Images/appideationh.png';
import automationh from '../Images/automationh.png';
import brandidentityh from '../Images/brandidentityh.png';
import webdesignh from '../Images/webdesignh.png';
import { Link } from 'react-router-dom';
import ExploreCS from './ExploreCS';
import Rainbow from './Rainbow';
import RfSlider from './RfSlider';

function HomeSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const texts = [
    "We craft a compelling brand identity and positioning that resonates with your target audience.",
    "Stand out from your competitors, Get unique designs created by our Design experts which blends with your Brand.",
    "We ideate responsive, user-friendly dynamic applications from scratch to provide an immersive brand experience and drive customers to your business.",
    "From AI, ML, Social Media Management to our CRM apps and tools, we will help you ace in Brand management.",
  ];

  // Auto-slide functionality
  useEffect(() => {
    const autoSlide = setInterval(() => {
      nextSlide();
    }, 5000); // Slide interval of 5 seconds

    return () => clearInterval(autoSlide); // Clean up the interval on unmount
  }, [currentIndex]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const slides = [
    {
      id: 1,
      content: (
        <div className="sContentOne">
          <Link to={'/contact'} className="addToCart">
            <button>Contact</button>
          </Link>
          <img src={brandidentityh} alt="" className='scImg'/>
          <div className="imgtxt">
            <div className="brain"><img src={brain} alt="" /></div>
            <div className="btxt"><p>{texts[0]}</p></div>
          </div>
        </div>
      )
    },
    {
      id: 2,
      content: (
        <div className="sContentOne">
          <Link to={'/contact'} className="addToCart">
            <button>Contact</button>
          </Link>
          <img src={webdesignh} alt="" className='scImg' style={{width: '73%'}}/>
          <div className="imgtxt imgtxtwd">
            <div className="brain"><img src={webdesign} alt="" /></div>
            <div className="btxt"><p>{texts[1]}</p></div>
          </div>
        </div>
      )
    },
    {
      id: 3,
      content: (
        <div className="sContentOne">
          <Link to={'/contact'} className="addToCart">
            <button>Contact</button>
          </Link>
          <img src={appideationh} alt="" className='scImg' style={{width: '80%'}}/>
          <div className="imgtxt imgtxtai">
            <div className="brain"><img src={bulb} alt="" /></div>
            <div className="btxt"><p>{texts[2]}</p></div>
          </div>
        </div>
      )
    },
    {
      id: 4,
      content: (
        <div className="sContentOne">
          <Link to={'/contact'} className="addToCart">
            <button>Contact</button>
          </Link>
          <img src={automationh} alt="" className='scImg' style={{width: '85%'}}/>
          <div className="imgtxt imgtxtam">
            <div className="brain"><img src={play} alt="" /></div>
            <div className="btxt"><p>{texts[3]}</p></div>
          </div>
        </div>
      )
    }
  ];

  return (
    <div className="homeSliderMain">
      <div className="homeSlider">
        <div className="hSHeading">
          <h1>We are Admired for..</h1>
        </div>

        <div className="sliderMain">
          <div className="hSBtns">
            <div className="left" onClick={prevSlide}> <img src={leftaa} alt="" /> </div>
            <div className="rgt" onClick={nextSlide}> <img src={leftaa} alt="" /> </div>
          </div>
          <div className="sliderWindow">
            <div className="sliderContent" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
              {slides.map((slide) => (
                <div key={slide.id} className="sContentOne">
                  {slide.content}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="exploreServicesMain">
          <Link to={'/services'} className="lm-btn ex-btn">
            <button className='lm'>
              Explore Services 
              <div className="arrows">
                <img src={rightag} alt="arrow1" className='arrow1'/>
                <img src={rightag} alt="arrow1" className='arrow2'/>
              </div>
            </button>
            <div className="btn-r"></div>
            <div className="btn-w"></div>
          </Link>
        </div>
      </div>

      <ExploreCS/>
      {/* <RfSlider/> */}
      <Rainbow/>

      <div className="ourStrategy">
        <div className="osHeading"><h1>Our Strategy</h1></div>
        <div className="osImg"> <img src={osnew} alt="" /> </div>
      </div>
      <div className="popularBrands">
        <div className="pbHeading"><h1>Popular Brands we Crafted..</h1></div>
        <div className="pbImg"> <img src={pbnew} alt="" /> </div>
      </div>
    </div>
  );
}

export default HomeSlider;
